/* eslint-disable no-console */
import { call, put, delay, take, select } from '@redux-saga/core/effects';
import { setUser } from '@sentry/browser';

import { adminApiClient, authApiClient } from 'services';
import authToken from 'models/authToken';
import { getFullUsernameFromEmail } from 'lib/email';
import { handleClientError } from 'services/client';
import { setEmailInLocalStorage } from 'store/auth/helpers';
import { aclFetch } from 'store/acl/actions';
import { hasToken } from './selectors';
import { ACCESS_TOKEN_LOADED, accessTokenLoaded, authorizationFailed } from './actions';

export function* loadAccessToken() {
    let accessToken = authToken.get();
    const isTokenValid = yield select(hasToken);

    if (authToken.isExpired() || !isTokenValid) {
        const rawAccessToken = yield call(authApiClient.fetchToken);
        accessToken = {
            raw: rawAccessToken,
            ...authToken.parse(rawAccessToken),
        };
        authToken.store(accessToken);
    }

    setEmailInLocalStorage(accessToken?.sub);
    yield call(setUser, { username: getFullUsernameFromEmail(accessToken?.sub || '') });
    yield put(accessTokenLoaded(accessToken));
}

export function* authorize() {
    adminApiClient.addEventListener('error', (event) => handleClientError(event, [401, 403]));

    try {
        while (true) {
            yield call(loadAccessToken);
            yield delay(authToken.expiresIn());
        }
    } catch (error) {
        yield put(authorizationFailed());

        console.error(error, 'authorize error');
    }
}

export function* fetchAcl() {
    yield take(ACCESS_TOKEN_LOADED);
    yield put(aclFetch());
}

export default [authorize(), fetchAcl()];
