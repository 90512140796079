import { call, put, take, takeLatest, select, delay } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { omit } from 'lodash';
import config from 'config';

import generateSearchParameters from 'store/utils';
import { setPreferredAssetListType } from 'store/asset-list/utils';

import { clearSearch } from 'store/ui/actions';
import { SET_ASSETS_LIST_TYPE } from 'store/ui/actionTypes';
import { getApiClient } from 'services';
import { ASSET_CHANGE, ASSET_SAVE } from 'store/assets/actionTypes';
import { selectNewsroom } from 'store/newsroom/selectors';
import { setAssets, addAssets, fetchError, setHighlight, filtersClear } from './actions';
import * as ActionType from './actionTypes';
import { LINKEDPROVIDERS_FETCH_SUCCESS } from '../providers/actionTypes';

function* fetchList({ list }) {
    try {
        // extract necessary data from the store
        const {
            assetList: { filters: selectedFilters, sortType },
            ui: {
                search: { query },
            },
            providers,
            newsroom,
        } = yield select();

        let newsrooms = providers.linked;
        if (list === 'linked' && !newsrooms) {
            ({
                payload: { linked: newsrooms },
            } = yield take(LINKEDPROVIDERS_FETCH_SUCCESS));
        }
        // omit the filters in linked results
        const parameters = generateSearchParameters(
            list === 'linked'
                ? {
                      ...omit(selectedFilters, ['tags', 'stories', 'categories']),
                      statuses: [{ value: 'active', label: config.asset.labels.active }],
                  }
                : selectedFilters,
            sortType
        );

        // search in all accessible newsrooms for linked results
        const provider =
            list === 'linked' ? newsrooms && newsrooms.filter((item) => item !== newsroom).join(',') : newsroom;
        // make sure no call is made if there are no providers to fetch assets for
        // i.e. when linked providers list has not yet been fetched and search has been initiated for linked results
        if (provider) {
            const response = yield call(getApiClient(provider).fetchFilteredAssets, {
                provider,
                query,
                ...parameters,
            });

            yield put(setAssets({ list, ...response }));
        }
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch asset list',
            extras: {
                error,
            },
        });
        yield put(fetchError({ list, error: String(error) }));
    }
}

function* fetchMore({ list }) {
    try {
        const {
            assetList: {
                [list]: { nextUrl },
            },
        } = yield select();
        const newsroom = yield select(selectNewsroom);

        const response = yield call(getApiClient(newsroom).fetchMoreAssets, nextUrl);
        yield put(addAssets({ list, ...response }));
    } catch (error) {
        yield put(fetchError({ list }));
    }
}

const fetchingList = (type, list) => (action) => action.type === type && action.list === list;

export function* highlightAsset({ id, asset }) {
    const assetId = id || asset.id;
    yield put(
        setHighlight({
            value: true,
            assetId,
        })
    );
    yield delay(1500);
    yield put(
        setHighlight({
            value: false,
            assetId,
        })
    );
}

function* setAssetsListType({ assetsListType, isPodcastOnly }) {
    setPreferredAssetListType({ assetsListType });
    yield put(clearSearch());
    yield put(filtersClear({ list: 'main', assetsListType, isPodcastOnly, isChangingAssetListType: true }));
}

export default [
    takeLatest(
        [
            fetchingList(ActionType.FETCH_LIST, 'main'),
            ActionType.FILTERS_SET,
            ActionType.FILTERS_REMOVE,
            ActionType.FILTERS_CLEAR,
            ActionType.SORT_TYPE_SET,
        ],
        fetchList
    ),
    takeLatest(fetchingList(ActionType.FETCH_LIST, 'linked'), fetchList),
    takeLatest(fetchingList(ActionType.FETCH_MORE, 'main'), fetchMore),
    takeLatest(fetchingList(ActionType.FETCH_MORE, 'linked'), fetchMore),
    takeLatest([ASSET_CHANGE, ASSET_SAVE], highlightAsset),
    takeLatest(SET_ASSETS_LIST_TYPE, setAssetsListType),
];
