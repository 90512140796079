import config from 'config';

import Client from './client';
import preRequest from './api-client/pre-request';
import * as assets from './api-client/assets';
import * as categories from './api-client/categories';
import * as live from './api-client/live';
import * as series from './api-client/series';
import * as stream from './api-client/stream';
import * as tags from './api-client/tags';

const svpApiProxyClient = new Client({ baseUrl: `${config.apiProxy.host}${config.apiProxy.path}/` });

Object.entries({
    preRequest,
    ...assets,
    ...categories,
    ...live,
    ...series,
    ...stream,
    ...tags,
}).forEach(([k, v]) => {
    if (typeof v === 'function') {
        svpApiProxyClient[k] = v.bind(svpApiProxyClient);
    }
});

export default svpApiProxyClient;
