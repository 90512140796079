import { useDispatch, useSelector } from 'react-redux';
import type { Control, UseFormRegister } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';

import { uploadVideoPoster } from 'store/video/upload/actions';
import { isReady } from 'store/video/selectors';
import { isVideoPosterUploading } from 'store/video/upload/selectors';
import { isAudio, isLive, wasLive, isLivingThumbnailAvailable } from 'models/asset';

import VideoFrameForm from 'components/video/VideoPoster/VideoFrameForm';
import SuggestedThumbnails from 'components/video/VideoPoster/SuggestedThumbnails';
import config from 'config';
import { LivingThumbnail } from './LivingThumbnail';
import { PosterManager } from './PosterManager';
import { useFetchThumbnails } from './hooks/poster';
import type { FormValues, ImageType } from '../types';

import css from './Poster.module.scss';

type PosterProps = {
    asset: Asset;
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
};

export function Poster({ asset, control, register }: PosterProps) {
    const { provider, id } = asset;

    const dispatch = useDispatch();

    const uploading = useSelector((state) => isVideoPosterUploading(state, { provider, id, type: 'main' }));
    const ready = useSelector((state) => isReady(state, { id, provider }));

    const { thumbnails, areThumbnailsLoading } = useFetchThumbnails({ asset });

    const onVideoPosterUpload = (file: File, imageType: ImageType = 'main') => {
        dispatch(uploadVideoPoster(provider, id, file, imageType));
    };
    const { imageTypes } = config.defaultNewsroomConfig;

    return (
        <section className={css.wrapper} data-testid="imagePoster">
            {!isAudio(asset) && (
                <div className={css.greybox}>
                    <div className={css.frameSelector}>
                        <VideoFrameForm
                            disabled={uploading || !ready}
                            isLive={isLive(asset)}
                            onVideoPosterUpload={onVideoPosterUpload}
                        />
                    </div>
                    <div className={css.suggestions}>
                        {isLivingThumbnailAvailable(asset) ? (
                            <LivingThumbnail wasLive={wasLive(asset)} register={register} />
                        ) : (
                            <SuggestedThumbnails id={id} thumbnails={thumbnails} loading={areThumbnailsLoading} />
                        )}
                    </div>
                    <p className={css.info}>*this feature is applicable to the main image only</p>
                </div>
            )}
            <div className={css.posters}>
                {imageTypes.map((type) => (
                    <PosterManager
                        key={type}
                        asset={asset}
                        type={type}
                        control={control}
                        ready={ready}
                        onVideoPosterUpload={onVideoPosterUpload}
                    />
                ))}
            </div>
        </section>
    );
}
