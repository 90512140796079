import { all, takeEvery, select, call, put } from '@redux-saga/core/effects';

import { reportMessageToSentry } from 'lib/error';
import adminApiClient from 'services/admin-api-client';
import * as Assets from 'store/assets/actionTypes';
import * as AssetList from 'store/asset-list/actionTypes';
import * as Stories from './actionTypes';
import * as Actions from './actions';
import { getStories } from './selectors';
/**
 *
 * @param {String} provider
 * @param {Array} stories
 * @returns {IterableIterator<*>}
 */
function* filterUnfetched(provider, stories) {
    // filter out the `null` stories that are used somewhere in the app but haven't been fetched yet
    // eslint-disable-next-line no-unused-vars
    const missing = Object.entries(stories).filter(([, item]) => item.story === null);

    // filter out those stories that are already being fetched
    // eslint-disable-next-line no-unused-vars
    const unfetched = missing.filter(([, item]) => item.isFetching === false);

    // if there are any stories left to be fetched
    if (!unfetched.length) {
        return false;
    }
    const ids = unfetched.map(([id]) => id);
    return yield put(Actions.fetchStories({ ids, provider }));
}

function* fetchMissingStories() {
    try {
        const stories = yield select(getStories);

        yield Object.entries(stories).map(([provider, providerStories]) => filterUnfetched(provider, providerStories));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch missing stories',
            extras: {
                error,
            },
        });
    }
}

/**
 * @deprecated Prefer to use `useGetSeriesQuery` hook from adminBffSdk
 */
export function* fetchStories({ ids, provider }) {
    try {
        const stories = yield all(ids.map((id) => call(adminApiClient.fetchStories, { provider, id })));
        yield put(Actions.fetchStoriesSuccess({ provider, stories }));
    } catch (error) {
        yield put(Actions.fetchStoriesError({ ids, provider, error: error.message }));
    }
}

export default [
    takeEvery(
        [AssetList.SET_ASSETS, AssetList.ADD_ASSETS, Assets.ASSET_FETCH_SUCCESS, Assets.ASSET_SAVE_SUCCESS],
        fetchMissingStories
    ),
    takeEvery(Stories.STORIES_FETCH, fetchStories),
];
