import { UPLOAD_STATE } from 'store/video/upload/reducers';

export const getVideoUpload = (state, { provider, id }) => state?.video?.upload?.[provider]?.[id]?.video || {};
export const getVideoPosterUpload = (state, { provider, id }) => state?.video?.upload?.[provider]?.[id]?.poster || {};
export const getVideoUploadErrors = (state, { provider, id }) => state?.video?.upload?.errors?.[provider]?.[id] || [];

export const isVideoUploading = (state, { provider, id }) =>
    getVideoUpload(state, { provider, id })?.state === UPLOAD_STATE.UPLOAD;

export const getVideoFrame = (state, { provider, id }) => getVideoPosterUpload(state, { provider, id })?.frame || 0;

export const getUploadedVideoPosterUri = (state, { provider, id, type }) =>
    getVideoPosterUpload(state, { provider, id })?.[type]?.uri;

export const getPosterPreviewUrl = (state, { provider, id, type }) =>
    getVideoPosterUpload(state, { provider, id })?.[type]?.objectUrl;

export const isVideoPosterUploading = (state, { provider, id, type }) =>
    getVideoPosterUpload(state, { provider, id })?.[type]?.state === UPLOAD_STATE.UPLOAD;

export const getTranscodingState = (state, { provider, id }) =>
    getVideoUpload(state, { provider, id })?.state || UPLOAD_STATE.DONE;

export const isVideoDone = (state, { provider, id }) =>
    getTranscodingState(state, { provider, id }) === 'done' && !isVideoUploading(state, { provider, id });
