import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropzone } from 'components/video/Dropzone/Dropzone';
import { UploadConfirmationDialog } from 'components/video/UploadConfirmationDialog';
import { Notification } from '@schibsted-svp/react-ui';
import { uploadVideoPoster, uploadVideo } from 'store/video/upload/actions';
import { uploadSubtitles } from 'store/uploads/subtitles/actions';
import { hasPlayback } from 'models/asset';
import { getAssetChangesBeforeReplace } from 'components/video/ChangeStreamUrlsDialog/helper';
import { saveAsset } from 'store/assets/actions';
import { isCustomPreviewUploadDialogOpen } from 'store/ui/assetForm/selectors';
import { areTranscodingsDone } from 'store/video/selectors';
import { isVideoUploading } from 'store/video/upload/selectors';
import { isTrimPending } from 'store/clips/selectors';
import { useAssetForm } from 'components/AssetForm/hooks/useAssetForm';
import type { Asset } from '@schibsted-svp/svp-api-types';
import validator from './validator';

interface DragAndDropUploadProps {
    provider: string;
    asset: Asset;
}

type FormSubtitles = {
    language: string;
    url: string;
    default: boolean;
};

export function DragAndDropUpload({ provider, asset }: DragAndDropUploadProps) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [streamFile, setStreamFile] = useState(undefined);
    const [subtitlesFiles, setSubtitlesFiles] = useState(undefined);
    const dispatch = useDispatch();
    const { id } = asset;

    const { formApi } = useAssetForm(asset);
    const { getValues, setValue } = formApi;

    const isCustomPreviewDialogOpen = useSelector((state) =>
        isCustomPreviewUploadDialogOpen(state, { provider, assetId: id })
    );
    const subtitles = (asset && getValues('additional.subtitles')) || [];
    const transcodingsDone = useSelector((state) => areTranscodingsDone(state, { provider, id }));
    const videoUploading = useSelector((state) => isVideoUploading(state, { provider, id }));
    const trimPending = useSelector((state) => isTrimPending(state, { provider, id }));

    const canUploadVideo = transcodingsDone && !videoUploading && !trimPending;

    const onUpload = (files) => {
        if (isCustomPreviewDialogOpen) {
            return;
        }

        const { isValid, message, imageFile, videoFile, subtitleFiles } = validator(files, {
            subtitlesLimit: subtitles.length,
        });

        if (!isValid) {
            Notification.notify.error(message);
            return;
        }

        if (message) {
            Notification.notify.warn(message);
        }

        if (imageFile) {
            dispatch(uploadVideoPoster(provider, asset.id, imageFile, 'main'));
        }
        if (videoFile && !canUploadVideo) {
            Notification.notify.error('Cannot upload file - processing previous one.');
            return;
        }
        if (videoFile || subtitleFiles.length) {
            setShowConfirmation(true);
            setStreamFile(videoFile);
            setSubtitlesFiles(subtitleFiles);
        }
    };

    const resetConfirmation = () => {
        setShowConfirmation(false);
        setStreamFile(undefined);
        setSubtitlesFiles(undefined);
    };

    const processConfirmation = async (languageMap, preservePreview, overlayUrl) => {
        resetConfirmation();

        if (streamFile) {
            const changes = getAssetChangesBeforeReplace(asset);
            if (changes) {
                await new Promise((resolve, reject) => {
                    dispatch(
                        saveAsset({
                            id: asset.id,
                            provider: asset.provider,
                            changes,
                            resolve,
                            reject,
                        })
                    );
                });
            }
            dispatch(
                uploadVideo(provider, asset.id, streamFile, {
                    preservePoster: true,
                    preservePreview,
                    overlayUrl,
                })
            );
        }

        if (subtitlesFiles.length) {
            await Promise.allSettled(
                subtitlesFiles.map(async (subtitleFile) => {
                    const language = languageMap[subtitleFile.name];

                    const subtitle = await (uploadSubtitles(
                        provider,
                        asset.id,
                        language,
                        subtitleFile
                    ) as unknown as FormSubtitles);
                    const index = subtitles.findIndex((sub) => sub.language === language);
                    if (index !== -1) {
                        setValue('additional.subtitles', subtitles.splice(index, 1, subtitle));

                        return;
                    }
                    subtitles.push(subtitle);
                    setValue('additional.subtitles', subtitles);
                })
            );
        }
    };

    return (
        <>
            <Dropzone disabled={showConfirmation || isCustomPreviewDialogOpen} onUpload={onUpload} fixed multiple />
            <UploadConfirmationDialog
                subtitleFiles={subtitlesFiles}
                videoFile={streamFile}
                onCancel={resetConfirmation}
                onConfirm={processConfirmation}
                opened={showConfirmation}
                hasPlayback={hasPlayback(asset)}
                provider={provider}
            />
        </>
    );
}
