import * as PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { MdCrop, MdDeleteForever } from 'react-icons/md';
import { Spinner } from '@schibsted-svp/react-ui';
import PosterPlaceholder from './PosterPlaceholder';
import css from './PosterPreview.module.scss';

const cln = classnames.bind(css);

function PosterPreview({
    src,
    width,
    loading,
    highlighted,
    toggleCropImageDialog,
    clearUploadedImage,
    handlePosterChange,
    onVideoPosterUpload,
    type,
}) {
    // Don't add query params if it's a base64 data or blob
    const url = /^(data:image|blob:)/.test(src) ? src : `${src}?t[]=${width}q80`;

    return (
        <div className={css.wrapper}>
            {!src ? (
                <PosterPlaceholder
                    handlePosterChange={handlePosterChange}
                    onVideoPosterUpload={onVideoPosterUpload}
                    type={type}
                />
            ) : (
                <>
                    <img
                        src={url}
                        alt="Poster preview"
                        className={cln(css.image, { 'image--highlighted': highlighted })}
                    />
                    {loading ? (
                        <Spinner speed={120} containerClassName={css.spinner} />
                    ) : (
                        <ul className={css.toolbox}>
                            <li>
                                <MdCrop onClick={toggleCropImageDialog} />
                            </li>
                            {clearUploadedImage && (
                                <li>
                                    <MdDeleteForever onClick={clearUploadedImage} />
                                </li>
                            )}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
}

PosterPreview.propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    loading: PropTypes.bool,
    toggleCropImageDialog: PropTypes.func.isRequired,
    clearUploadedImage: PropTypes.func,
    highlighted: PropTypes.bool,
    handlePosterChange: PropTypes.func.isRequired,
    onVideoPosterUpload: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['main', 'featured', 'front']).isRequired,
};

PosterPreview.defaultProps = {
    src: null,
    width: 380,
    loading: false,
    highlighted: false,
    clearUploadedImage: undefined,
};

export default PosterPreview;
