import { reportMessageToSentry } from 'lib/error';
import { call, put, select, takeLatest, takeEvery, take, delay, race, fork, join } from '@redux-saga/core/effects';
import { Notification } from '@schibsted-svp/react-ui';
import { getNewsroomConfig } from 'config';
import { DEFERRED } from 'store/deferred';
import { adminBffClient, getApiClient } from 'services';
import { api as adminBffSdk } from 'services/admin-bff-sdk/generated';
import {
    getSetupSourceType,
    prepareLogoOverlayInput,
    prepareMetadataInputForUpdate,
    prepareScheduleInput,
    prepareSetupInput,
} from 'services/admin-bff/live';
import SELECT_NEWSROOM from 'store/newsroom/actionTypes';
import { authEmailSelector } from 'store/auth/selectors';
import { isElementalEncoder, isEmptyEncoder, isExternalOrRtmpEncoder, isMediaLiveEncoder } from 'models/asset';
import { atLeastOneEventIsRunning } from 'models/multiLiveEvent';
import { SECOND } from 'lib/time';
import * as actions from './actions';
import { getAsset } from '../assets/selectors';
import { findLiveEncoderEvents, getMediaLiveChannel } from './selectors';
import { ELEMENTAL_EVENT_STATE_CHANGE, LIVE_ENCODERS_SUCCESS, MEDIALIVE_CHANNEL_STATE_CHANGE } from './actions';

const FETCH_ENCODERS_RETRY_TIMEOUT = SECOND * 2;

/**
 * @param {Object} attributes
 * @param {String} attributes.newsroom
 */
export function* fetchLiveEncodersRequest({ newsroom }) {
    try {
        const { encoders, channels } = yield call(adminBffClient.fetchLiveEncoders, { newsroom });

        yield put(actions.fetchingLiveEncodersSuccess(newsroom, encoders, channels));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch live encoders',
            extras: {
                error,
            },
        });

        yield put(actions.fetchingLiveEncodersFailure(newsroom, error.message));
    }
}

export function* fetchLiveEncodersWhenMultiEventNotStartedYet({ newsroom, encoders = [] }) {
    const startingMultiEvents = encoders
        .map(({ events }) => events)
        .flat()
        .filter(
            ({ status, asset }, _, allEvents) =>
                status.toLowerCase() === 'pending' &&
                allEvents.find(
                    (event) =>
                        asset &&
                        event.asset &&
                        event.asset.id === asset.id &&
                        event.asset.provider === asset.provider &&
                        event.status.toLowerCase() === 'running'
                )
        );

    if (startingMultiEvents.length > 0) {
        yield delay(FETCH_ENCODERS_RETRY_TIMEOUT);
        yield put(actions.fetchLiveEncoders(newsroom));
    }
}

/**
 * @param {Object} attributes
 * @param {String} attributes.newsroom
 * @param {Object} attributes.values
 */
export function* liveStreamCreationRequest({ newsroom, values, [DEFERRED]: deferred }) {
    try {
        const createdBy = yield select(authEmailSelector);
        const { assetDefaults } = getNewsroomConfig(newsroom).live;
        const { category: categoryId, ...defaults } = assetDefaults;

        let result;

        if (values.type === 'RTMP') {
            const { streamKey, title, description, streamType } = { ...defaults, ...values };

            const createRtmpPushPromise = yield put(
                adminBffSdk.endpoints.createRtmpPush.initiate({
                    newsroom,
                    streamKey,
                    streamType,
                    metadata: { title, description, categoryId, createdBy },
                })
            );
            const { data, error } = yield createRtmpPushPromise;

            if (error) {
                throw new Error(error);
            }

            result = data.createRtmpPush;
        } else {
            result = yield call(adminBffClient.createLiveStream, {
                newsroom,
                values: { categoryId, ...defaults, ...values, createdBy },
            });
        }

        deferred(result);

        const sourceType = getSetupSourceType(values);
        yield put(
            actions.creatingLiveStreamSuccess(newsroom, result.asset.id, sourceType, values.startTime, values.duration)
        );
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to create live stream',
            extras: {
                error,
            },
        });

        deferred(error);

        yield put(actions.creatingLiveStreamFailure(newsroom, error.message));
    }
}

/**
 * @param {Object} attributes
 * @param {Number} attributes.assetId
 * @param {String} attributes.newsroom
 * @param {Object} attributes.values
 */
export function* liveStreamPreparationRequest({ assetId, newsroom, values, [DEFERRED]: deferred }) {
    try {
        const editedBy = yield select(authEmailSelector);
        const { assetDefaults } = getNewsroomConfig(newsroom).live;
        const { category: categoryId, ...defaults } = assetDefaults;
        const isEdited = values.hasEvent || values.channelId;

        if (isEdited) {
            yield put(actions.editLiveStreamRequest(newsroom, assetId));
        }

        let result;

        if (values.type === 'RTMP') {
            const { streamKey, streamType } = values;

            const prepareRtmpPushPromise = yield put(
                adminBffSdk.endpoints.prepareRtmpPush.initiate({
                    newsroom,
                    assetId,
                    streamKey,
                    metadata: { editedBy },
                    streamType,
                })
            );
            const { data, error } = yield prepareRtmpPushPromise;

            if (error) {
                throw new Error(error);
            }

            result = data;
        } else {
            const formValues = { categoryId, ...defaults, ...values, editedBy };
            const prepareLiveStreamPromise = yield put(
                adminBffSdk.endpoints.prepareLiveStream.initiate({
                    newsroom,
                    assetId,
                    metadata: prepareMetadataInputForUpdate(formValues),
                    schedule: prepareScheduleInput(formValues),
                    livePreview: values?.livePreview || false,
                    vertical: values?.vertical || false,
                    setup: prepareSetupInput(formValues),
                    overlay: prepareLogoOverlayInput(formValues),
                    deinterlace: values?.deinterlace || false,
                    audioNormalization: values?.audioNormalization || false,
                })
            );

            const { data, error } = yield prepareLiveStreamPromise;
            if (error) {
                throw new Error(error);
            }

            result = data;
        }

        deferred(result);
        if (isEdited) {
            yield put(actions.editLiveStreamSuccess(newsroom, assetId));
            return;
        }

        yield put(actions.preparingLiveStreamSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to prepare live stream',
            extras: {
                error,
            },
        });

        deferred(error);
        yield put(actions.editLiveStreamFailure(newsroom, assetId));
        yield put(actions.preparingLiveStreamFailure(newsroom, assetId, error.message));
    }
}

export function* liveStreamUpdateRequest({ assetId, newsroom, values, [DEFERRED]: deferred }) {
    const errorMessage = 'Failed to update live stream';
    try {
        const editedBy = yield select(authEmailSelector);
        const result = yield call(adminBffClient.updateLiveStream, {
            newsroom,
            assetId,
            values,
            editedBy,
        });

        if (!result) {
            throw new Error(errorMessage);
        }

        deferred(result);

        yield put(actions.updatingLiveStreamSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to update live stream',
            extras: {
                error,
            },
        });

        deferred(error);

        yield put(actions.updatingLiveStreamFailure(newsroom, assetId, error.message));
    }
}

export function* liveStreamStatusChanged({ assetId, newsroom, data }) {
    if (!['starting', 'error'].includes(data.state.toLowerCase())) {
        return;
    }

    let started = false;
    const timestamp = Date.now();
    while (!started && Date.now() <= timestamp + 180000) {
        yield delay(FETCH_ENCODERS_RETRY_TIMEOUT);
        yield put(actions.fetchLiveEncoders(newsroom));
        const { encoders } = yield take(LIVE_ENCODERS_SUCCESS);
        const encoder = encoders.find(({ id }) => id === data.encoderId);
        const event = encoder?.events?.find(({ id }) => id === data.eventId);
        started = !event || ['running', 'error'].includes(event.status.toLowerCase());
    }
    if (started) {
        yield put(actions.startingLiveStreamSuccess(newsroom, assetId));
        return;
    }
    yield put(actions.startingLiveStreamFailure(newsroom, assetId, ''));
}

function* waitForEncoderStart({ asset }) {
    if (isElementalEncoder(asset)) {
        const { result } = yield race({
            result: take((action) => {
                return (
                    [LIVE_ENCODERS_SUCCESS].includes(action.type) &&
                    action.newsroom === asset.provider &&
                    action.assetId === asset.id &&
                    action.encoders
                        .map(({ events }) => events)
                        .flat()
                        .filter((event) => event.asset.id === asset.id && event.asset.provider === asset.provider)
                        .every((event) => ['running', 'error'].includes(event.status.toLowerCase()))
                );
            }),
            timeout: delay(180000),
        });
        return Boolean(result);
    }

    if (isMediaLiveEncoder(asset)) {
        const { result } = yield race({
            result: take((action) => {
                return (
                    action.type === MEDIALIVE_CHANNEL_STATE_CHANGE &&
                    action.newsroom === asset.provider &&
                    action.assetId === asset.id &&
                    ['running', 'error'].includes(action.data?.state?.toLowerCase())
                );
            }),
            timeout: delay(180000),
        });
        return Boolean(result);
    }

    return true;
}

export function* startLiveStreamRequest({ assetId, newsroom }) {
    try {
        const asset = yield select(getAsset, { id: assetId, provider: newsroom });
        const task = yield fork(waitForEncoderStart, { asset });

        const editedBy = yield select(authEmailSelector);
        const started = yield call(adminBffClient.startLiveStream, {
            assetId,
            newsroom,
            editedBy,
        });

        if (!started) {
            throw new Error('Unable to start live stream');
        }

        yield join(task);
        yield put(actions.startingLiveStreamSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: error.message,
            extras: {
                error,
            },
        });
        yield put(actions.startingLiveStreamFailure(newsroom, assetId, 'Cannot start a live stream'));
    }
}

function* waitForEncoderStop({ asset }) {
    if (isExternalOrRtmpEncoder(asset) || isEmptyEncoder(asset)) {
        return true;
    }

    if (isMediaLiveEncoder(asset)) {
        const channel = yield select(getMediaLiveChannel, { assetId: asset.id, provider: asset.provider });
        if (['creating', 'idle'].includes(channel?.state)) {
            return true;
        }
    }

    if (isElementalEncoder(asset)) {
        const events = yield select(findLiveEncoderEvents, { assetId: asset.id, provider: asset.provider });
        if (events.length === 0 || !atLeastOneEventIsRunning(events)) {
            return true;
        }
    }

    const { result } = yield race({
        result: take((action) => {
            return (
                [MEDIALIVE_CHANNEL_STATE_CHANGE, ELEMENTAL_EVENT_STATE_CHANGE].includes(action.type) &&
                action.newsroom === asset.provider &&
                action.assetId === asset.id &&
                ((action.type === MEDIALIVE_CHANNEL_STATE_CHANGE &&
                    ['stopped', 'error'].includes(action.data?.state?.toLowerCase())) ||
                    (action.type === ELEMENTAL_EVENT_STATE_CHANGE &&
                        ['stopped', 'error'].includes(action.data?.state?.toLowerCase())))
            );
        }),
        timeout: delay(180000),
    });

    return Boolean(result);
}

export function* stopLiveStreamRequest({ assetId, newsroom, isEdited = false }) {
    try {
        const asset = yield select(getAsset, { id: assetId, provider: newsroom });
        const task = yield fork(waitForEncoderStop, { asset });

        const editedBy = yield select(authEmailSelector);
        const stopped = yield call(adminBffClient.stopLiveStream, {
            assetId,
            newsroom,
            editedBy,
        });

        if (!stopped) {
            throw new Error('Unable to stop live stream');
        }

        yield join(task);
        if (!isEdited) {
            yield put(actions.stoppingLiveStreamSuccess(newsroom, assetId));
        }
    } catch (error) {
        reportMessageToSentry({
            message: error.message,
            extras: {
                error,
            },
        });
        yield put(actions.stoppingLiveStreamFailure(newsroom, assetId, 'Cannot stop a live stream'));
    }
}

function* afterLiveStreamStartTimeBreakpoint({ assetId, newsroom }) {
    try {
        const asset = yield select(getAsset, { id: assetId, provider: newsroom });
        yield call(waitForEncoderStart, { asset });
        yield put(actions.startingLiveStreamSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: error.message,
            extras: {
                error,
            },
        });
        yield put(actions.startingLiveStreamFailure(newsroom, assetId, 'Cannot start a live stream'));
    }
}

function* afterLiveStreamEndTimeBreakpoint({ assetId, newsroom }) {
    try {
        const asset = yield select(getAsset, { id: assetId, provider: newsroom });
        yield call(waitForEncoderStop, { asset });
        yield put(actions.stoppingLiveStreamSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: error.message,
            extras: {
                error,
            },
        });
        yield put(actions.stoppingLiveStreamFailure(newsroom, assetId, 'Cannot stop a live stream'));
    }
}

export function stopLiveStreamSuccess() {
    Notification.notify.success('Live stream has been stopped.');
}

export function stopLiveStreamFailure() {
    Notification.notify.error('Cannot stop the live stream.');
}

export function editLiveStreamSuccess() {
    Notification.notify.success('Live stream has been edited successfully.');
}

export function* insertLiveAdRequest({ newsroom, assetId, duration }) {
    const errorMessage = 'Unable to insert ads';
    try {
        const inserted = yield call(adminBffClient.insertAd, {
            assetId,
            newsroom,
            duration,
        });

        if (!inserted) {
            throw new Error(errorMessage);
        }

        yield put(actions.insertingLiveAdSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: errorMessage,
            extras: {
                error,
            },
        });
        yield put(actions.insertingLiveAdFailure(newsroom, assetId, error.message));
    }
}

export function insertLiveAdFailure() {
    Notification.notify.error('An error occurred during ads placement.');
}

export function* cancelLiveAdRequest({ newsroom, assetId }) {
    const errorMessage = 'Unable to cancel ads';
    try {
        const cancelled = yield call(adminBffClient.cancelAd, {
            assetId,
            newsroom,
        });

        if (!cancelled) {
            throw new Error(errorMessage);
        }

        yield put(actions.cancellingLiveAdSuccess(newsroom, assetId));
    } catch (error) {
        reportMessageToSentry({
            message: errorMessage,
            extras: {
                error,
            },
        });
        yield put(actions.cancellingLiveAdFailure(newsroom, assetId, error.message));
    }
}

export function cancelLiveAdFailure() {
    Notification.notify.error('An error occurred during ads abortion.');
}

export function* fetchLiveAssets({ provider }) {
    try {
        const assets = yield call(getApiClient(provider).fetchLiveAssets, { provider });

        yield put(actions.fetchLiveAssetsSuccess(assets));
    } catch (error) {
        const message = 'Failed to fetch live assets';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        yield put(actions.fetchLiveAssetsError(message));
    }
}

export function* fetchWasLiveAssets({ provider }) {
    try {
        const assets = yield call(getApiClient(provider).fetchWasLiveAssets, { provider });

        yield put(actions.fetchWasLiveAssetsSuccess(assets));
    } catch (error) {
        const message = 'Failed to fetch was live assets';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        yield put(actions.fetchWasLiveAssetsError(message));
    }
}

export function* handleNewsroomChange({ newsroom }) {
    yield put(actions.fetchLiveAssets({ provider: newsroom }));
    yield put(actions.fetchLiveEncoders(newsroom));
}

export function* unpublishLiveAssetRequest({ newsroom, assetId }) {
    const errorMessage = 'Failed to unpublish live assets';
    try {
        const editedBy = yield select(authEmailSelector);
        const success = yield call(adminBffClient.unpublishLiveAsset, { newsroom, assetId, editedBy });
        if (!success) {
            throw new Error(errorMessage);
        }

        yield put(actions.unpublishLiveAssetSuccess(newsroom, assetId));
    } catch (error) {
        Notification.notify.error(errorMessage);
        reportMessageToSentry({
            message: errorMessage,
            extras: {
                error,
            },
        });
        yield put(actions.unpublishLiveAssetFailure(newsroom, assetId));
    }
}

export function* duplicatetLiveStreamRequest({ assetId, newsroom }) {
    try {
        const duplicatedBy = yield select(authEmailSelector);
        const duplicated = yield put(
            adminBffSdk.endpoints.DuplicateLiveStream.initiate({
                assetId,
                newsroom,
                duplicatedBy,
            })
        );

        const { data, error } = yield duplicated;
        if (error) {
            throw new Error(error);
        }

        const result = data.duplicateLiveStream;

        if (!result) {
            throw new Error('Unable to duplicate live stream');
        }

        const { asset } = result;
        yield put(actions.duplicateLiveStreamSuccess(newsroom, assetId, asset.id));
    } catch (error) {
        reportMessageToSentry({
            message: error.message,
            extras: {
                error,
            },
        });
        yield put(actions.duplicateLiveStreamFailure(newsroom, assetId, 'Cannot duplicate a live stream'));
    }
}

export default [
    takeLatest(
        [
            actions.LIVE_ENCODERS_REQUEST,
            actions.START_LIVE_STREAM_SUCCESS,
            actions.START_LIVE_STREAM_FAILURE,
            actions.STOP_LIVE_STREAM_SUCCESS,
            actions.STOP_LIVE_STREAM_FAILURE,
            actions.LIVE_STREAM_CREATION_SUCCESS,
            actions.LIVE_STREAM_CREATION_FAILURE,
            actions.LIVE_STREAM_PREPARATION_SUCCESS,
            actions.LIVE_STREAM_PREPARATION_FAILURE,
            actions.LIVE_STREAM_UPDATE_SUCCESS,
            actions.LIVE_STREAM_UPDATE_FAILURE,
            actions.EDIT_LIVE_STREAM_SUCCESS,
        ],
        fetchLiveEncodersRequest
    ),
    takeLatest(actions.LIVE_ENCODERS_SUCCESS, fetchLiveEncodersWhenMultiEventNotStartedYet),
    takeLatest(actions.LIVE_STREAM_CREATION_REQUEST, liveStreamCreationRequest),
    takeLatest(actions.LIVE_STREAM_PREPARATION_REQUEST, liveStreamPreparationRequest),
    takeLatest(actions.LIVE_STREAM_UPDATE_REQUEST, liveStreamUpdateRequest),
    takeLatest(actions.START_LIVE_STREAM_REQUEST, startLiveStreamRequest),
    takeLatest(actions.STOP_LIVE_STREAM_REQUEST, stopLiveStreamRequest),
    takeLatest(actions.STOP_LIVE_STREAM_SUCCESS, stopLiveStreamSuccess),
    takeLatest(actions.STOP_LIVE_STREAM_FAILURE, stopLiveStreamFailure),
    takeLatest(actions.EDIT_LIVE_STREAM_SUCCESS, editLiveStreamSuccess),
    takeEvery(actions.AFTER_LIVE_STREAM_START_TIME, afterLiveStreamStartTimeBreakpoint),
    takeEvery(actions.AFTER_LIVE_STREAM_END_TIME, afterLiveStreamEndTimeBreakpoint),
    takeLatest(actions.INSERT_LIVE_AD_REQUEST, insertLiveAdRequest),
    takeLatest(actions.INSERT_LIVE_AD_FAILURE, insertLiveAdFailure),
    takeLatest(actions.CANCEL_LIVE_AD_REQUEST, cancelLiveAdRequest),
    takeLatest(actions.CANCEL_LIVE_AD_FAILURE, cancelLiveAdFailure),
    takeLatest(actions.FETCH_WAS_LIVE_ASSETS, fetchWasLiveAssets),
    takeLatest(actions.FETCH_LIVE_ASSETS, fetchLiveAssets),
    takeLatest(actions.UNPUBLISH_LIVE_ASSET_REQUEST, unpublishLiveAssetRequest),
    takeEvery(actions.ELEMENTAL_EVENT_STATE_CHANGE, liveStreamStatusChanged),
    takeLatest(SELECT_NEWSROOM, handleNewsroomChange),
    takeLatest(actions.DUPLICATE_LIVE_STREAM_REQUEST, duplicatetLiveStreamRequest),
];
