import { isImageType, readFileFromDisk } from 'lib/file';
import { loadImage } from 'lib/image';

/**
 * @param {Array<File>} files
 * @param {Object} attributes
 * @property {Number} attributes.minWidth
 * @property {Number} attributes.minHeight
 * @property {Number} attributes.maxWidth
 * @property {Number} attributes.maxHeight
 * @return {Promise<Object>}
 */
export default async function validate(files, { minWidth, minHeight, maxWidth, maxHeight }) {
    if (!files || !Array.isArray(files) || !files.length) {
        return {
            isValid: false,
            message: 'No file selected',
        };
    }

    if (files.length > 1) {
        return {
            isValid: false,
            message: 'Selected too many files. Try again with the only one.',
        };
    }

    const file = files.find(isImageType);
    if (!file) {
        return {
            isValid: false,
            message: 'Selected file is not an image',
        };
    }

    const dataUrl = await readFileFromDisk(file);
    const image = await loadImage(dataUrl);
    if (image.width < minWidth || image.height < minHeight) {
        return {
            isValid: false,
            message: 'Too small image selected',
        };
    }

    if (image.width > maxWidth || image.height > maxHeight) {
        return {
            isValid: false,
            message: 'Too big image selected',
        };
    }

    return {
        isValid: true,
        file,
    };
}
