import { call, put, takeLatest } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { getApiClient } from 'services';

import {
    seriesFetchSuccess,
    seriesFetchError,
    seriesAddSuccess,
    seriesDeleteSuccess,
    seriesUpdateSuccess,
} from './actions';

import { SERIES_FETCH, SERIES_ADD, SERIES_DELETE, SERIES_UPDATE } from './actionTypes';

export function* seriesFetch({ provider, categoryId }) {
    try {
        const series = yield call(getApiClient(provider).fetchSeries, { provider, categoryId });
        yield put(seriesFetchSuccess(provider, categoryId, series));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch series',
            extras: {
                error,
            },
        });
        yield put(seriesFetchError(provider, categoryId, error.message));
    }
}

export function* seriesAdd({ provider, categoryId, seasonNumber, seasonTitle, meta }) {
    try {
        const series = yield call(getApiClient(provider).addSeries, {
            categoryId,
            provider,
            title: seasonTitle,
            seasonNumber,
        });
        yield put(seriesAddSuccess(provider, categoryId, { ...series }, meta));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to add series',
            extras: {
                error,
            },
        });
    }
}

export function* updateSeries({ provider, categoryId, seasonNumber, seasonTitle, meta }) {
    try {
        const series = yield call(getApiClient(provider).updateSeries, {
            categoryId,
            provider,
            title: seasonTitle,
            seasonNumber,
        });
        yield put(seriesUpdateSuccess(provider, categoryId, { ...series }, meta));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to update series',
            extras: {
                error,
            },
        });
    }
}

export function* deleteSeries({ provider, categoryId, seasonNumber, meta }) {
    try {
        yield call(getApiClient(provider).deleteSeries, { categoryId, provider, seasonNumber });
        yield put(seriesDeleteSuccess(provider, categoryId, seasonNumber, meta));
    } catch (error) {
        if (error.response && error.response.status === 403) {
            const response = yield error.response.json();
            const validationError = new Error(response.errors);
            validationError.status = 400;
        } else {
            reportMessageToSentry({
                message: 'Failed to delete series',
                extras: {
                    error,
                },
            });
        }
    }
}

export default [
    takeLatest(SERIES_FETCH, seriesFetch),
    takeLatest(SERIES_ADD, seriesAdd),
    takeLatest(SERIES_UPDATE, updateSeries),
    takeLatest(SERIES_DELETE, deleteSeries),
];
