import { buffers, eventChannel } from '@redux-saga/core';
import { delay, apply, call, put, select, take, fork, takeEvery } from '@redux-saga/core/effects';

import config, { getS3Uri, getDefaultCategoryFor } from 'config';
import { getApiClient, adminApiClient, adminBffClient } from 'services';
import { isGeoblocked, getAccess, hasFreeAccess } from 'models/asset';

import FileUploader from 'services/file-uploader';
import { getMediaType, getSubtitleFile, isAudioOrVideoType, isImageType } from 'lib/file';
import { getFileNameFromUrl } from 'lib/url';
import { isEmpty } from 'lodash';

import { DEFERRED } from 'store/deferred';
import { completeSubtitlesUpload, failSubtitlesUpload, UPLOAD_SUBTITLES } from 'store/uploads/subtitles/actions';
import { createAssetSuccess } from 'store/assets/actions';
import { resetStreamConversion } from 'store/video/stream-conversion/sagas';

import { getAsset } from 'store/assets/selectors';
import { watchForMqttEvents } from 'store/mqtt/client';

import {
    completeVideoPosterUpload,
    completeVideoUpload,
    CREATE_VIDEO,
    transcodeVideo,
    UPLOAD_VIDEO,
    UPLOAD_VIDEO_COMPLETE,
    UPLOAD_VIDEO_POSTER,
    UPLOAD_CANCEL,
    uploadVideo,
    videoPosterUploadError,
    videoTranscodingFailure,
    videoUploadError,
    videoUploadProgress,
} from './actions';

const UPLOAD_ABORTED_MESSAGE = 'User aborted the upload';

let fileUploader;

const mapLogoOverlay = ({ overlayUrl }) => ({
    sourceUri: overlayUrl,
    params: {
        origin: 'tr',
        width: 100,
        height: 100,
        x: 0,
        y: 0,
    },
});

export function* requestVideoTranscode({
    provider,
    id,
    uri,
    options: { preservePoster, preservePreview, overlayUrl } = {},
}) {
    const asset = yield select(getAsset, { id, provider });

    const transformations = {
        ...(preservePoster && { poster: null }),
        ...(preservePreview && { preview: null }),
    };

    if (overlayUrl) {
        transformations.overlays = [mapLogoOverlay({ overlayUrl })];
    }

    const body = {
        sourceUri: encodeURI(uri),
        ...(!isEmpty(transformations) && { transformations }),
    };

    body.protections = {
        // eslint-disable-next-line no-nested-ternary
        geoRestriction: isGeoblocked(asset) ? (config.swedishNewsrooms.includes(provider) ? 'sweden' : 'norway') : null,
        accessLevel: hasFreeAccess(asset) ? 'free' : getAccess(asset)?.[0] || null,
    };

    try {
        yield put(transcodeVideo(provider, id));
        yield call(adminApiClient.transcodeIngest, { id, provider, body });
    } catch (error) {
        yield put(videoTranscodingFailure(provider, id, error.message));
    }
}

// FIXME: Retrying needed because SVP API is fucked up and throws 503 during simultaneous asset creation
export function* createAsset({ provider, changes }) {
    const retry = 3;
    let error;

    for (let i = 1; i <= retry; i += 1) {
        try {
            return yield call(getApiClient(provider).saveAsset, { provider, changes });
        } catch (apiError) {
            error = apiError;

            if (i < retry) {
                yield delay(200);
            }
        }
    }

    throw error;
}

export function* createVideo({ provider, file, uri, options, [DEFERRED]: deferred }) {
    const changes = {
        title: uri ? getFileNameFromUrl(uri) : file?.name,
        category: {
            id: getDefaultCategoryFor(provider),
        },
        assetType: getMediaType(file),
        duration: 1,
    };

    let asset;
    try {
        asset = yield call(createAsset, { provider, changes });
        yield put(createAssetSuccess(asset));

        if (uri) {
            yield call(requestVideoTranscode, { provider, id: asset.id, uri, options });
        } else {
            yield put(uploadVideo(provider, asset.id, file, options));
        }

        yield fork(watchForMqttEvents, { topic: `ingest/${provider}/${asset.id}/+/+` });
        deferred(asset.id);
    } catch (error) {
        if (asset && asset.id) {
            yield put(videoUploadError(provider, asset.id, file, error.message));
        }
        deferred(error);
    }
}

export function* uploadVideoSaga({ provider, id, file, options }) {
    try {
        yield call(resetStreamConversion, { provider, id });

        yield apply(fileUploader, fileUploader.uploadFile, [{ provider, id, file, options }]);
    } catch (error) {
        /**
         * This is expected error for user aborted upload
         * https://github.com/TTLabs/EvaporateJS/blob/311136d08fe19b745753f804618b67694bd87d7d/evaporate.js#L613
         */
        if (error === UPLOAD_ABORTED_MESSAGE) {
            return;
        }
        yield put(videoUploadError(provider, id, file, error.message));
    }
}

export function* cancelUploadSaga({ provider, id, file, filename }) {
    try {
        yield apply(fileUploader, fileUploader.cancelUploadFile, [filename]);
    } catch (error) {
        yield put(videoUploadError(provider, id, file, error.message));
    }
}

export function* uploadVideoPoster({ provider, id, file, imageType }) {
    try {
        yield apply(fileUploader, fileUploader.uploadFile, [{ provider, id, file, imageType }]);
    } catch (error) {
        yield put(videoPosterUploadError(provider, id, file, error.message));
    }
}

export function* uploadSubtitlesSaga({ provider, assetId, file, language, [DEFERRED]: deferred }) {
    try {
        const subtitleFile = yield getSubtitleFile(file);
        const path = yield apply(fileUploader, fileUploader.uploadFile, [
            { provider, id: assetId, file: subtitleFile },
        ]);

        const { url } = yield call(adminBffClient.uploadSubtitles, {
            newsroom: provider,
            assetId,
            languageCode: language.split('_', 1).pop(),
            source: getS3Uri(path),
        });

        yield put(completeSubtitlesUpload(provider, assetId, language, url));

        deferred({ language, url, default: false });
    } catch (error) {
        yield put(failSubtitlesUpload(provider, assetId, language, error.message));
        deferred(error);
    }
}

export function* initializeUploader() {
    fileUploader = yield call(FileUploader.create);

    return eventChannel((emitter) => {
        fileUploader.registerHandler(FileUploader.EVENT_PROGRESS, (data) =>
            emitter({
                event: FileUploader.EVENT_PROGRESS,
                data,
            })
        );
        fileUploader.registerHandler(FileUploader.EVENT_COMPLETE, (data) =>
            emitter({
                event: FileUploader.EVENT_COMPLETE,
                data,
            })
        );
        fileUploader.registerHandler(FileUploader.EVENT_ERROR, (data) =>
            emitter({
                event: FileUploader.EVENT_ERROR,
                data,
            })
        );

        return () => {}; // no-op
    }, buffers.sliding());
}

export function* watchForUpload() {
    const channel = yield call(initializeUploader);

    while (true) {
        const { event, data = {} } = yield take(channel);
        const { file } = data;

        if (isImageType(file)) {
            const { provider, id } = data;
            switch (event) {
                case FileUploader.EVENT_COMPLETE:
                    yield put(completeVideoPosterUpload(provider, id, getS3Uri(data.object), data.imageType));
                    break;
                case FileUploader.EVENT_ERROR:
                    yield put(videoPosterUploadError(provider, id, file, data.message));
                    break;
                default: // skip unknown events
            }
        }

        if (isAudioOrVideoType(file)) {
            const { provider, id, options, progress, secondsLeft, filename } = data;
            switch (event) {
                case FileUploader.EVENT_PROGRESS:
                    yield put(videoUploadProgress(provider, id, progress, secondsLeft, filename));
                    break;

                case FileUploader.EVENT_COMPLETE: {
                    const uri = getS3Uri(data.object);
                    yield put(completeVideoUpload(provider, id, uri, options));
                    break;
                }

                case FileUploader.EVENT_ERROR:
                    yield put(videoUploadError(provider, id, file, data.message));
                    break;
                default: // skip unknown events
            }
        }
    }
}

export default [
    watchForUpload(),
    takeEvery(UPLOAD_VIDEO_POSTER, uploadVideoPoster),
    takeEvery(CREATE_VIDEO, createVideo),
    takeEvery(UPLOAD_VIDEO, uploadVideoSaga),
    takeEvery(UPLOAD_CANCEL, cancelUploadSaga),
    takeEvery(UPLOAD_VIDEO_COMPLETE, requestVideoTranscode),
    takeEvery(UPLOAD_SUBTITLES, uploadSubtitlesSaga),
];
