import * as ReactDOM from 'react-dom';

import { init as initSentry } from '@sentry/browser';

import config from 'config';
import { Notification } from '@schibsted-svp/react-ui';

import { adminApiClient, svpApiProxyClient } from 'services';

import { handleClientError } from 'services/client';
import Application from './Application';
import setupStore from './store';
import images from './lib/image';
import device from './lib/device';

const store = setupStore();

declare let __RELEASE__: string;

if (config.sentry && __RELEASE__) {
    initSentry({
        ...config.sentry,
        release: __RELEASE__,
        autoSessionTracking: false,
    });
}

// api service configuration

images.configure({ ...config.images, getPixelRatio: device.getPixelRatio });

adminApiClient.addEventListener('error', (event) => handleClientError(event, [401]));
svpApiProxyClient.addEventListener('error', (event) => handleClientError(event, [401]));

const render = (Component) => {
    ReactDOM.render(
        <>
            <Component store={store} />
            <Notification.Container />
        </>,
        document.getElementById('app')
    );
};

render(Application);

if (module.hot) {
    module.hot.accept('./Application', () => {
        render(Application);
    });
}
