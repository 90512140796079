import { call, put, takeEvery, select } from '@redux-saga/core/effects';
import { adminApiClient, adminBffClient, authApiClient, playerApiClient, svpApiProxyClient } from 'services';
import { fetchList, filtersSet } from 'store/asset-list/actions';
import { getFilters } from 'store/asset-list/selectors';
import { defaultFilters } from 'store/asset-list/state';
import { categoriesFetch } from 'store/categories/actions';
import config, { getNewsroomConfig } from 'config';
import SELECT_NEWSROOM from './actionTypes';

const { assetType } = config.asset.labels;

export function* changeNewsroom({ newsroom }) {
    [adminApiClient, adminBffClient, authApiClient, playerApiClient, svpApiProxyClient].forEach((client) => {
        client.setProvider(newsroom);
    });

    const filters = yield select(getFilters);
    const { defaultAssetListType, podcastsOnly } = getNewsroomConfig(newsroom);

    const isPodcast =
        (defaultAssetListType === assetType.audio || filters.assetTypes[0].value === assetType.audio) && podcastsOnly;

    yield put(
        filtersSet({
            list: 'main',
            filters: {
                ...filters,
                ...defaultFilters(),
                ...(defaultAssetListType && {
                    assetTypes: [{ value: defaultAssetListType, label: assetType[defaultAssetListType] }],
                }),
                isPodcast,
                categories: undefined,
                tags: undefined,
                stories: undefined,
                customFields: undefined,
            },
        })
    );

    yield call(fetchList, { payload: { list: 'main' } });
    yield put(categoriesFetch({ provider: newsroom }));
}

export default [takeEvery(SELECT_NEWSROOM, changeNewsroom)];
