import { call, put, takeEvery, select, take, fork } from '@redux-saga/core/effects';
import * as ReactGA from 'react-ga';
import { reportMessageToSentry } from 'lib/error';
import { Notification } from '@schibsted-svp/react-ui';
import { getApiClient, adminBffClient } from 'services';
import { addAssets, setAssets } from 'store/asset-list/actions';
import { fetchAsset, saveAsset } from 'store/assets/actions';
import { cuttingStart } from 'store/video/cutting/actions';
import { getAsset } from 'store/assets/selectors';
import { getUpdatedHighlights } from 'store/clips/selectors';
import { waitForAssetFetch } from 'store/assets/sagas';
import { watchForMqttEvents } from 'store/mqtt/client';
import { ASSET_SAVE_SUCCESS } from 'store/assets/actionTypes';
import { resetStreamConversion } from 'store/video/stream-conversion/sagas';

import { isVod } from 'models/asset';

import {
    CREATE_CLIP,
    CREATE_CLIP_ERROR,
    CREATE_CLIP_SUCCESS,
    FETCH_CLIPS,
    FETCH_CLIPS_ERROR,
    FETCH_CLIPS_SUCCESS,
    TRIM_VIDEO,
    TRIM_VIDEO_ERROR,
    TRIM_VIDEO_SUCCESS,
    RESET_TRIM,
    RESET_TRIM_SUCCESS,
    RESET_TRIM_FAILURE,
    createClipStart,
    createClipSuccess,
    createClipError,
    fetchClipsSuccess,
    fetchClipsError,
    trimVideoStart,
    trimVideoError,
    trimVideoSuccess,
    resetTrimSuccess,
    resetTrimFailure,
} from './actions';

/**
 * @param {Error} error
 * @param {String?} message
 */
function handleError(error, message) {
    Notification.notify.error(message || error.message);
    reportMessageToSentry({
        message: message || error.message,
        extras: {
            error,
        },
    });
}

export function* createClipSaga({ assetId, provider, playback }) {
    try {
        const { begin: startTime, end: endTime } = playback;

        // make call to API
        const { assetId: newAssetId, errorMessage } = yield call(adminBffClient.createClip, {
            provider,
            assetId,
            cutRanges: [{ begin: startTime, end: endTime }],
        });
        if (errorMessage) {
            yield put(createClipError(assetId, provider, errorMessage));
            return;
        }

        yield put(fetchAsset({ id: newAssetId, provider }));
        yield call(waitForAssetFetch, newAssetId);
        yield put(createClipStart(assetId, newAssetId, provider));

        yield call(ReactGA.event, {
            category: 'Video',
            action: 'Clip',
        });

        const { streamType } = yield select(getAsset, { id: assetId, provider });

        // skip VoD's as they are not supported atm
        if (!isVod({ streamType })) {
            yield put(cuttingStart(provider, newAssetId));
            yield fork(watchForMqttEvents, {
                topic: [`ingest/${provider}/${newAssetId}/cut/+`, `ingest/${provider}/${newAssetId}/transcode/+`],
            });

            const chapters = yield select(getUpdatedHighlights, { assetId, provider, playback });

            if (chapters) {
                yield put(
                    saveAsset({
                        id: newAssetId,
                        provider,
                        changes: { additional: { chapters } },
                    })
                );
            }
            return;
        }
        yield put(createClipSuccess(assetId, newAssetId, provider));
    } catch (error) {
        yield put(createClipError(assetId, provider, error.message));
    }
}

export function createClipErrorSaga({ assetId, error }) {
    handleError(error, `An error occurred while creating a new clip from ${assetId} video.`);
}

export function createClipSuccessSaga({ newAssetId }) {
    Notification.notify.success(`A new ${newAssetId} clip has been created successfully.`);
}

export function* fetchClipsSaga({ assetId, provider, nextUrl: url }) {
    try {
        const apiClient = getApiClient(provider);
        const { assets, nextUrl } = url
            ? yield call(apiClient.fetchMoreAssets, url)
            : yield call(apiClient.fetchClips, { provider, originAssetId: assetId });

        yield put(fetchClipsSuccess(assetId, provider, nextUrl, assets));
    } catch (error) {
        yield put(fetchClipsError(assetId, provider, error.message));
    }
}

export function fetchClipsErrorSaga({ assetId, error }) {
    handleError(error, `An error occurred while fetching the clips for ${assetId} video.`);
}

export function* fetchClipsSuccessSaga({ assetId, nextUrl, assets }) {
    if (nextUrl) {
        yield put(addAssets({ id: assetId, assets }));
    } else {
        yield put(setAssets({ id: assetId, assets }));
    }
}

export function* trimVideoSaga({ assetId, provider, playback }) {
    try {
        const { begin: startTime, end: endTime } = playback;

        // make API call
        const success = yield call(adminBffClient.trimAsset, {
            provider,
            assetId,
            cutRanges: [{ begin: startTime, end: endTime }],
        });

        if (!success) {
            const errorMessage = `Error occured when trimming asset ${assetId}`;
            yield put(trimVideoError(assetId, provider, errorMessage));
            return;
        }

        yield put(trimVideoStart(assetId, provider));

        yield call(ReactGA.event, {
            category: 'Video',
            action: 'Trim',
        });

        const { streamType } = yield select(getAsset, { id: assetId, provider });

        // skip VoD's as they are not supported atm
        if (!isVod({ streamType })) {
            yield call(resetStreamConversion, { provider, id: assetId });

            yield put(cuttingStart(provider, assetId));
            yield fork(watchForMqttEvents, {
                topic: `ingest/${provider}/${assetId}/cut/+`,
            });

            const chapters = yield select(getUpdatedHighlights, { assetId, provider, playback });

            if (chapters !== null) {
                yield put(
                    saveAsset({
                        id: assetId,
                        provider,
                        changes: { additional: { chapters } },
                    })
                );
            }
            return;
        }

        yield put(trimVideoSuccess(assetId, provider));
    } catch (error) {
        yield put(trimVideoError(assetId, provider, error.message));
    }
}

export function trimVideoErrorSaga({ assetId, error }) {
    handleError(error, `An error occurred while trimming the ${assetId} clip.`);
}

export function trimVideoSuccessSaga({ assetId }) {
    Notification.notify.success(`The ${assetId} clip has been trimmed successfully.`);
}

export function resetTrimSuccessSaga({ assetId }) {
    Notification.notify.success(`The trim from ${assetId} video has been reset successfully.`);
}

export function resetTrimFailureSaga({ assetId, error }) {
    handleError(error, `An error occurred while reseting trim of the ${assetId} video.`);
}

export function* resetTrimSaga({ assetId, provider, previousData }) {
    try {
        const {
            streamUrls: { hls },
        } = previousData;

        yield call(resetStreamConversion, { provider, id: assetId });

        const durationsForAllResolutions = yield call(adminBffClient.getDurationFromUrl, hls);

        const { duration } = durationsForAllResolutions[0];

        const durationInMs = duration * 1000;

        yield put(saveAsset({ id: assetId, provider, changes: { ...previousData, duration: durationInMs } }));
        yield take(ASSET_SAVE_SUCCESS);
        yield put(resetTrimSuccess({ assetId, provider }));
    } catch (error) {
        yield put(resetTrimFailure({ assetId, provider, error: error.message }));
    }
}

export default [
    takeEvery(CREATE_CLIP, createClipSaga),
    takeEvery(CREATE_CLIP_ERROR, createClipErrorSaga),
    takeEvery(CREATE_CLIP_SUCCESS, createClipSuccessSaga),
    takeEvery(FETCH_CLIPS, fetchClipsSaga),
    takeEvery(FETCH_CLIPS_ERROR, fetchClipsErrorSaga),
    takeEvery(FETCH_CLIPS_SUCCESS, fetchClipsSuccessSaga),
    takeEvery(TRIM_VIDEO, trimVideoSaga),
    takeEvery(TRIM_VIDEO_ERROR, trimVideoErrorSaga),
    takeEvery(TRIM_VIDEO_SUCCESS, trimVideoSuccessSaga),
    takeEvery(RESET_TRIM, resetTrimSaga),
    takeEvery(RESET_TRIM_SUCCESS, resetTrimSuccessSaga),
    takeEvery(RESET_TRIM_FAILURE, resetTrimFailureSaga),
];
