import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { UseFormReturn } from 'react-hook-form';
import { Notification } from '@schibsted-svp/react-ui';
import type { Optional } from 'types';
import { getApiClient } from 'services';
import { usePrevious } from 'hooks/usePrevious';
import { getSeries, getFetchingFlag, getSeasonsArray } from 'store/series/selectors';
import { getSeriesFlag } from 'store/categories/selectors';
import { seriesFetch } from 'store/series/actions';
import type { FormValues } from '../../types';

export function useFetchSeriesData({ provider, categoryId }: { provider: string; categoryId: number }) {
    const dispatch = useDispatch();

    const isSeries = useSelector((state) => getSeriesFlag(state, { provider, id: categoryId }));
    const series = useSelector((state) => getSeries({ state, provider, categoryId }));
    const seriesIsLoading = useSelector((state) => getFetchingFlag(state, { provider, categoryId }));

    useEffect(() => {
        if (categoryId && isSeries) {
            dispatch(seriesFetch(provider, categoryId));
        }
    }, [isSeries, provider, categoryId, dispatch]);

    return { isSeries, series, seriesIsLoading };
}

export function useUpdateFormData({
    categoryId,
    assetCategoryId,
    formApi,
}: {
    categoryId: number;
    assetCategoryId: Optional<number>;
    formApi: UseFormReturn<FormValues>;
}) {
    const { setValue, resetField, trigger } = formApi;
    const prevCategoryId = usePrevious(categoryId);

    useEffect(() => {
        if (categoryId === prevCategoryId) {
            return;
        }
        if (categoryId === assetCategoryId) {
            resetField('series.seasonNumber');
            resetField('series.episodeNumber');
        } else {
            setValue('series.seasonNumber', null);
            setValue('series.episodeNumber', null);
        }
        trigger(['series.seasonNumber', 'series.episodeNumber']);
    }, [categoryId, prevCategoryId, assetCategoryId, resetField, setValue, trigger]);
}

export function useFillNext({
    provider,
    categoryId,
    seasonNumber,
    formApi: { setValue },
}: {
    provider: string;
    categoryId: number;
    seasonNumber: number;
    formApi: UseFormReturn<FormValues>;
}) {
    const series = useSelector((state) => getSeasonsArray(state, { provider, categoryId }));

    const [isNextLoading, setIsNextLoading] = useState(false);

    const fillNext = useCallback(async () => {
        try {
            setIsNextLoading(true);
            // apiClient is not TS ready but it should be replaced by RTK Query anyway
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { nextSeasonNumber, nextEpisodeNumber } = await getApiClient(provider).getSeriesNext({
                provider,
                categoryId,
                series,
                seasonNumber,
            });
            setValue('series.seasonNumber', nextSeasonNumber, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
            setValue('series.episodeNumber', nextEpisodeNumber, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        } catch (error) {
            Notification.notify.error('Cannot fetch next episode');
        } finally {
            setIsNextLoading(false);
        }
    }, [provider, categoryId, seasonNumber, series, setValue]);

    return { fillNext, isNextLoading };
}
