import { memo, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import * as ReactGA from 'react-ga';
import config from 'config';
import { reportMessageToSentry } from 'lib/error';
import { getCroppedImage, loadImage } from 'lib/image';
import { Button, Connector, Dialog, Notification } from '@schibsted-svp/react-ui';
import css from './CropImageDialog.module.scss';
import './ReactCrop.scss';

const [minimumWidth, minimumHeight] = config.images.poster.upload.minimumSize;

const initialCrop = {};

const initialCropLimit = {};

const availableAspectRatios = {
    '16:9': 16 / 9,
    '9:16': 9 / 16,
    '1:1': 1,
};

/**
 * @param {HTMLImageElement} img - Image File Object
 * @param {Number} scaleX
 * @param {Number} scaleY
 * @param {Object} crop - crop Object
 * @property {Number} crop.x
 * @property {Number} crop.y
 * @property {Number} crop.width
 * @property {Number} crop.height
 * @throws {String|undefined}
 */
function getValidationMessage(img, scaleX, scaleY, crop) {
    const width = Math.floor(crop.width * scaleX);
    if (width < minimumWidth) {
        return `The cropped image is ${width} wide but should be at least ${minimumWidth}.`;
    }

    const height = Math.floor(crop.height * scaleY);
    if (height < minimumHeight) {
        return `The cropped image is ${width} height but should be at least ${minimumWidth}.`;
    }

    return undefined;
}

/**
 * @param {String} message
 * @param {Object} [extras=]
 */
function logError(message, extras) {
    Notification.notify.error(message);
    reportMessageToSentry({
        message,
        extras,
    });
}

function CropImageDialog(props) {
    const { onVideoPosterUpload, opened, src, toggle, type } = props;
    const [crop, setCrop] = useState(initialCrop);
    const [aspect, setAspect] = useState();
    const [{ minHeight, minWidth, maxHeight, maxWidth, scaleX, scaleY }, setCropLimits] = useState(initialCropLimit);
    const imageUrl = src?.startsWith('http') ? `${src}?t[]=1440q80` : src;
    const isApplyButtonDisabled = !crop?.width && !crop?.height;

    useEffect(() => {
        if (opened) {
            ReactGA.modalview('crop-image');
        } else {
            setCrop(initialCrop);
            setCropLimits(initialCropLimit);
        }
    }, [opened]);

    const onImageError = () => {
        logError('Failed to load the image for cropping');
        setCropLimits({});
    };

    const onImageLoaded = (event) => {
        const image = event.currentTarget;
        const calculatedScaleX = image.naturalWidth / image.width;
        const calculatedScaleY = image.naturalHeight / image.height;

        setCropLimits({
            minHeight: minimumHeight / calculatedScaleY,
            maxHeight: image.height,
            minWidth: minimumWidth / calculatedScaleX,
            maxWidth: image.width,
            scaleX: calculatedScaleX,
            scaleY: calculatedScaleY,
        });
    };

    const onCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    const onAspectRatioChange = (event) => {
        const newAspect = Number(event.currentTarget.dataset.value);

        setAspect((prevAspect) => (prevAspect !== newAspect ? newAspect : undefined));

        setCrop((currentCrop) => {
            let width = newAspect <= 1 ? currentCrop.width : null;
            if (width && width < minWidth) {
                width = minWidth;
            }

            let height = newAspect >= 1 ? currentCrop.height : null;
            if (height && height < minHeight) {
                height = minHeight;
            }

            return makeAspectCrop({ width, height }, newAspect, maxWidth, maxHeight);
        });
    };

    const onCancelButtonClick = () => {
        toggle();
    };

    const onApplyButtonClick = async () => {
        try {
            const image = await loadImage(imageUrl, { crossOrigin: 'anonymous' });

            const validationMessage = getValidationMessage(image, scaleX, scaleY, crop);
            if (validationMessage) {
                Notification.notify.warn(validationMessage);
                return;
            }

            const croppedImage = await getCroppedImage(image, scaleX, scaleY, crop, 'thumbnail.jpg');

            onVideoPosterUpload(croppedImage, type);
            toggle();
        } catch (error) {
            logError('Failed during poster crop creation', { error });
        }
    };

    return (
        <Dialog isOpen={opened} heading="Crop image" onClose={toggle}>
            <Dialog.Section className={css.image}>
                <ReactCrop
                    aspect={aspect}
                    crop={crop}
                    onChange={onCropChange}
                    minHeight={minHeight}
                    minWidth={minWidth}
                    maxHeight={maxHeight}
                    maxWidth={maxWidth}
                >
                    <img src={imageUrl} onError={onImageError} onLoad={onImageLoaded} alt="crop" />
                </ReactCrop>
            </Dialog.Section>

            <Dialog.Section mode="flexRight" variant="darker">
                <Connector className={css.aspectRatio}>
                    {Object.entries(availableAspectRatios).map(([name, value]) => (
                        <Button
                            key={`ratio:${name}`}
                            type="button"
                            size="compact"
                            variant="standard"
                            selected={value === aspect}
                            className={css.ratioButton}
                            data-value={value}
                            onClick={onAspectRatioChange}
                        >
                            {name}
                        </Button>
                    ))}
                </Connector>
                <Button type="button" variant="standard" onClick={onCancelButtonClick}>
                    Cancel
                </Button>
                <Button type="button" disabled={isApplyButtonDisabled} onClick={onApplyButtonClick}>
                    Apply
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}

CropImageDialog.propTypes = {
    src: PropTypes.string.isRequired,
    opened: PropTypes.bool.isRequired,
    onVideoPosterUpload: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['main', 'featured', 'front']).isRequired,
};

export default memo(CropImageDialog);
