import config from 'config';

import Client from './client';
import preRequest from './api-client/pre-request';
import * as acl from './api-client/acl';
import * as ingest from './api-client/ingest';
import * as assets from './api-client/assets';
import * as categories from './api-client/categories';
import * as live from './api-client/live';
import * as series from './api-client/series';
import * as stories from './api-client/stories';
import * as stream from './api-client/stream';
import * as tags from './api-client/tags';

const adminApiClient = new Client({ baseUrl: `${config.api.host}${config.api.path}` });

Object.entries({
    preRequest,
    ...acl,
    ...ingest,
    // TODO: migrate to admin-bff
    ...stories,
    // TODO: remove below services from this api client when we migrate fully to svp-api-proxy
    ...assets,
    ...categories,
    ...live,
    ...series,
    ...stream,
    ...tags,
}).forEach(([k, v]) => {
    if (typeof v === 'function') {
        adminApiClient[k] = v.bind(adminApiClient);
    }
});

export default adminApiClient;
