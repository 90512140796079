import * as PropTypes from 'prop-types';
import { Button, Notification } from '@schibsted-svp/react-ui';
import { MdAdd } from 'react-icons/md';
import { Dropzone } from 'components/video/Dropzone/Dropzone';
import config from 'config';
import { reportMessageToSentry } from 'lib/error';
import validate from 'components/video/VideoPoster/PosterUploader.validator';
import css from './PosterPlaceholder.module.scss';

const [minWidth, minHeight] = config.images.poster.upload.minimumSize;
const [maxWidth, maxHeight] = config.images.poster.upload.maximumSize;

function PosterPlaceholder({ handlePosterChange, onVideoPosterUpload, type }) {
    const handlePosterDnD = async (files) => {
        try {
            const result = await validate(Array.from(files), { minWidth, minHeight, maxWidth, maxHeight });
            if (result.isValid) {
                onVideoPosterUpload(result.file, type);
            } else {
                Notification.notify.error(result.message);
            }
        } catch (error) {
            const message =
                'Something went wrong during upload. Please contact us on Slack or on mail:svp@schibsted.com';

            reportMessageToSentry({
                message,
                extras: { error },
            });
            Notification.notify.error(message);
        }
    };

    return (
        <Dropzone onUpload={handlePosterDnD} className={css.dropzone}>
            {() => (
                <Button type="label" size="small" iconOnly>
                    <input id="uploadInput" type="file" onChange={handlePosterChange} hidden />
                    <MdAdd size={20} />
                </Button>
            )}
        </Dropzone>
    );
}

PosterPlaceholder.propTypes = {
    handlePosterChange: PropTypes.func.isRequired,
    onVideoPosterUpload: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['main', 'featured', 'front']).isRequired,
};

export default PosterPlaceholder;
