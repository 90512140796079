import { useState, useEffect } from 'react';
import { useFormState, useFieldArray, type Control } from 'react-hook-form';
import { Button } from '@schibsted-svp/react-ui';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { getDuration } from 'models/asset';
import { unique } from 'lib/array';
import { usePlayer } from 'hooks/usePlayer';
import FormattedTime from 'components/core/FormattedTime';
import type { FormValues } from 'components/AssetForm/types';
import { MidrollsForm } from './MidrollsForm';

import css from './Midrolls.module.scss';

export type Midroll = {
    timeline: number;
};

type MidrollsProps = {
    asset: Asset;
    control: Control<FormValues>;
};

export function Midrolls({ asset, control }: MidrollsProps) {
    const { isSubmitting, defaultValues } = useFormState({ control });
    const { fields, insert, remove, replace } = useFieldArray({ control, name: 'additional.cuePoints' });

    // cue should be stored and saved manually because of a bug in useFieldArray
    const [cuePointsSync, setCuePointsSync] = useState(defaultValues.additional.cuePoints);
    const player = usePlayer();

    const excluded = unique(fields.map((item) => item.timeline));

    const onMidrollAdd = (item: Midroll) => {
        const index = fields.filter(({ timeline }) => timeline < item.timeline).length;
        insert(index, item);

        setCuePointsSync((prevCuePointsSync) => {
            const cueCopy = new Array(...prevCuePointsSync);
            cueCopy.splice(index, 0, item);
            return cueCopy;
        });
    };

    useEffect(() => {
        if (cuePointsSync.length !== fields.length) {
            replace(cuePointsSync);
        }
    }, [cuePointsSync, fields, replace]);

    const onMidrollRemove = (index: number) => {
        remove(index);
        setCuePointsSync((prevCuePointsSync) => {
            const cueCopy = new Array(...prevCuePointsSync);
            cueCopy.splice(index, 1);
            return cueCopy;
        });
    };
    return (
        <section>
            <MidrollsForm
                duration={getDuration(asset)}
                excluded={excluded}
                submitDisabled={isSubmitting}
                onMidrollAdd={onMidrollAdd}
            />
            {fields && (
                <ul className={css.list}>
                    {fields.map(({ id, timeline }, index) => (
                        <li key={`item:${id}:${timeline}`}>
                            <span>
                                Start time:{' '}
                                <FormattedTime
                                    onClick={(time: number) => {
                                        player?.seek(time);
                                    }}
                                    seconds={timeline}
                                />
                            </span>
                            <Button
                                size="compact"
                                variant="standard"
                                type="button"
                                onClick={() => onMidrollRemove(index)}
                            >
                                Delete
                            </Button>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
}
