import EventTarget from '@ungap/event-target';
import { authErrorMessage } from 'components/Auth';
import { Notification } from '@schibsted-svp/react-ui';
import authToken from 'models/authToken';
import { reportMessageToSentry } from 'lib/error';

function aliasRequestMethods() {
    ['get', 'post', 'put', 'delete', 'patch'].forEach((method) => {
        this[method] = function request(
            path,
            { provider = false, query = undefined, json = undefined, params = {} } = {}
        ) {
            return this.request(path, method, { provider, query, json, params });
        };
    });
}

export function handleClientError(event: Event, errorCodes: number[]) {
    const error = event instanceof CustomEvent ? event.detail.error : event;

    if (errorCodes.includes(Number(error.response.status))) {
        Notification.notify.error(authErrorMessage, {
            autoClose: false,
            toastId: 'AUTH_ERROR',
            onClose: () => {
                authToken.clear();
                setTimeout(() => window.location.reload(), 0);
            },
        });

        reportMessageToSentry({
            message: 'Auth error',
            extras: {
                error,
            },
        });
    }
}

type QueryType = { appName: string };

interface RequestError extends Error {
    response?: string;
}

class Client extends EventTarget {
    private baseUrl: string;

    private fetch?: (req: Request) => Promise<Response>;

    private query?: QueryType;

    private provider?: string;

    private preRequest?: (init?: RequestInit) => RequestInit;

    constructor({
        baseUrl,
        fetchFn = undefined,
        query = undefined,
    }: {
        baseUrl: string;
        fetchFn?: (req: Request) => Promise<Response>;
        query?: QueryType;
    }) {
        super();
        this.baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
        this.fetch = fetchFn || (window && window.fetch && window.fetch.bind(window));
        this.query = query;
        aliasRequestMethods.call(this);
    }

    /**
     *
     * @param {String} provider
     */
    setProvider(provider: string) {
        this.provider = provider;
    }

    /**
     *
     * @returns {String}
     */
    getProvider(): string {
        return this.provider;
    }

    /**
     *
     * @param {Object} options
     * @param {String} options.path
     * @param {String} options.method
     * @param {String} options.provider
     * @param {Object} options.query
     * @param {Object} options.params
     * @param {Object} options.json
     * @returns {Request}
     */
    getRequest({
        path,
        method,
        provider,
        query,
        params,
        json,
    }: {
        path: string;
        method: string;
        provider: string;
        query: QueryType;
        params: Record<string, unknown>;
        json: Record<string, unknown>;
    }): Request {
        const url = new URL(path.replace('{PROVIDER}', provider || this.getProvider()), this.baseUrl);

        if (this.query || query) {
            url.search = new URLSearchParams({ ...this.query, ...query }).toString();
        }

        const init: RequestInit = {
            method: method.toUpperCase(),
            headers: {},
            mode: 'cors',
            ...params,
        };

        if (json) {
            init.body = JSON.stringify(json);
            init.headers['content-type'] = 'application/json';
        }

        return new Request(url.toString(), this.preRequest ? this.preRequest(init) : init);
    }

    async request(
        path,
        method = 'get',
        {
            provider = '',
            query,
            json,
            params = {},
        }: {
            provider?: string;
            query?: QueryType;
            json?: Record<string, unknown>;
            params?: Record<string, unknown>;
        } = {}
    ) {
        let req;
        let res;
        try {
            req = this.getRequest({ path, provider, query, method, params, json });
            res = await this.fetch(req);
            if (res && res.status >= 400) {
                const error: RequestError = new Error(`statusText:${res.statusText}, status:${res.status}`);
                error.response = res;
                this.dispatchEvent(new CustomEvent('error', { detail: { path, error } }));
                throw error;
            }

            this.postRequest({ res, path });
        } catch (error) {
            this.postRequest({ req, res, path, error });
            throw error;
        }

        if ((res.headers.get('content-type') || '').match(/application\/(hal\+)?json/)) {
            return res.json();
        }

        if ((res.headers.get('content-type') || '').match(/image\/(gif|jpe?g|png)/)) {
            return res.blob();
        }

        return res.text();
    }

    // eslint-disable-next-line
    postRequest({ req, res, path, error }: { req?: Request; res?: string; path?: string; error?: RequestError }) {}

    // convert an object with filter values stored in arrays to a formatted string accepted by the api
    static prepareFilter(filters: Record<string, unknown[]>) {
        return Object.entries(filters)
            .map(([filter, values]) => `${filter}::${values.join(',')}`)
            .join('|');
    }
}

export default Client;
