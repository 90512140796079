import { useDispatch, useSelector } from 'react-redux';
import type { ChangeEvent } from 'react';
import CropImageDialog from 'components/video/CropImageDialog';
import PosterPreview from 'components/video/VideoPoster/PosterPreview';
import PosterUploader from 'components/video/VideoPoster/PosterUploader';
import { type Control, useController } from 'react-hook-form';
import { Label, Notification } from '@schibsted-svp/react-ui';
import { reportMessageToSentry } from 'lib/error';
import config from 'config';
import { revokeVideoPosterPreview } from 'store/video/upload/actions';
import useToggle from 'hooks/useToggle';
import { getPosterPreviewUrl, isVideoPosterUploading } from 'store/video/upload/selectors';
import validate from 'components/video/VideoPoster/PosterUploader.validator';
import type { Asset } from '@schibsted-svp/svp-api-types';
import css from './Poster.module.scss';
import { useUploadedPosterChange, useCategoryChange } from './hooks/poster';
import type { FormValues, ImageType } from '../types';

const [minWidth, minHeight] = config.images.poster.upload.minimumSize;
const [maxWidth, maxHeight] = config.images.poster.upload.maximumSize;

const typeToLabel = ({ type }) => (type === 'front' ? 'additional' : type);

interface PosterManagerProps {
    asset: Asset;
    type: ImageType;
    control: Control<FormValues>;
    ready: boolean;
    onVideoPosterUpload: (file: File, type: ImageType) => void;
}

export function PosterManager({ asset, type, control, ready, onVideoPosterUpload }: PosterManagerProps) {
    const {
        field: { value, onChange },
        fieldState: { isDirty },
        formState: {
            defaultValues: { images },
        },
    } = useController({ name: `images.${type}`, control });
    const dispatch = useDispatch();
    const { id, provider } = asset;

    const defaultValue = images[type];

    const poster = useSelector((state) => getPosterPreviewUrl(state, { provider, id, type })) || value;
    const uploading = useSelector((state) => isVideoPosterUploading(state, { provider, id, type }));

    useUploadedPosterChange({ provider, id, type, onChange });
    useCategoryChange({ asset, control, imageType: type, onChange });

    const [openedCropDialog, toggleOpenedCropDialog] = useToggle();

    const handleDeleteUploadedImage = () => {
        onChange(defaultValue);
        dispatch(revokeVideoPosterPreview(provider, id, type));
    };

    const handlePosterChange = async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            const result = await validate(Array.from(event.target.files), { minWidth, minHeight, maxWidth, maxHeight });
            if (result.isValid) {
                onVideoPosterUpload(result.file, type);
            } else {
                Notification.notify.error(result.message);
            }
        } catch (error) {
            const message =
                'Something went wrong during upload. Please contact us on Slack or on mail:svp@schibsted.com';

            reportMessageToSentry({
                message,
                extras: { error },
            });
            Notification.notify.error(message);
        }
    };

    return (
        <div className={css.posters}>
            <div className={css.posterContainer}>
                <Label className={css.label}>{typeToLabel({ type })} image</Label>
                <div className={css.poster}>
                    <PosterPreview
                        loading={uploading}
                        src={poster}
                        toggleCropImageDialog={toggleOpenedCropDialog}
                        clearUploadedImage={isDirty ? handleDeleteUploadedImage : undefined}
                        handlePosterChange={handlePosterChange}
                        onVideoPosterUpload={onVideoPosterUpload}
                        type={type}
                    />
                </div>
                <div className={css.uploader}>
                    <PosterUploader
                        disabled={uploading || !ready}
                        handlePosterChange={handlePosterChange}
                        isEmpty={poster === null}
                    />
                </div>
                <CropImageDialog
                    opened={openedCropDialog}
                    src={poster}
                    toggle={toggleOpenedCropDialog}
                    onVideoPosterUpload={onVideoPosterUpload}
                    type={type}
                />
            </div>
        </div>
    );
}
